import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  mixpanelToken!: string;
  constructor() {
    this.mixpanelToken = environment.mixpanel_token;
    mixpanel.init(this.mixpanelToken);
  }

  track(id: string, action: any = {}): void {
    mixpanel.track(id, {
      ...action,
      platform: 'Web'
    });
  }
}
