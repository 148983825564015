import { Action, createReducer, on } from '@ngrx/store';
import * as SubscriptionsActions from './subscriptions.actions';
import { initialState, SubscriptionState } from './subscriptions.state';

const subscriptionReducer = createReducer(
  initialState,
  on(SubscriptionsActions.setDataUsageSuccess, (state, { dataUsage }) => {
    const dataUsageState = state?.dataUsage ? [...state.dataUsage, ...dataUsage] : [...dataUsage];
    return {
      ...state,
      dataUsage: dataUsageState,
    };
  }),
  on(
    SubscriptionsActions.setSubscriptionsSuccess,
    (state, { subscriptions }) => {
      return {
        ...state,
        subscriptions: subscriptions,
      };
    }
  ),
  on(
    SubscriptionsActions.setSubscriptionStatusSuccess,
    (state, subscription: any) => {
      const updatedSubscriptions = state?.subscriptions?.map((plan: any) => {
        if (plan._id === subscription?._id) {
          return {
            ...plan,
            status: subscription.status,
            expiryDate: subscription.expiryDate,
            totalData: subscription.totalData,
          };
        } else if ( plan.status === 'active' && plan.preferredImsiId === subscription.preferredImsiId ) {
          return { ...plan, status: 'expired', expiryDate: new Date().toISOString(), expiredDate: new Date().toISOString() };
        }
        return plan;
      });
      return {
        ...state,
        subscriptions: updatedSubscriptions,
      };
    }
  ),
  on(
    SubscriptionsActions.addSubscriptionSuccess,
    (state, subscription: any) => {
      const newPlan = state?.subscriptions?.find(
        (plan) => plan._id === subscription._id
      );
      if (state.subscriptions) {
        return {
          ...state,
          subscriptions: newPlan
            ? state.subscriptions
            : [...state.subscriptions, subscription],
        };
      }
      return state;
    }
  ),
  on(SubscriptionsActions.addDataUsageSuccess, (state, { dataUsage }) => {
    const dataUsageState = state?.dataUsage ? [...state.dataUsage, dataUsage] : [dataUsage];
    return {
      ...state,
      dataUsage: dataUsageState,
    };
  })
);

export function reducer(state: SubscriptionState | undefined, action: Action) {
  return subscriptionReducer(state, action);
}
